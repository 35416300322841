import React from 'react';
import { useNavigate } from 'react-router-dom';

function Hero() {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/', { state: { fromSource: true } });
    };
    
    return (
        <div className="relative w-full h-screen bg-cover bg-center bg-hero-pattern">
            <div className="absolute inset-0 bg-white opacity-50 backdrop-filter backdrop-blur"></div>
            <div className="relative flex justify-center items-center h-full">
                <div className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl text-center text-black font-bold border-4 border-black px-6 sm:px-10 md:px-20 py-4 sm:py-6 md:py-8 font-playfair">
                    <h1>Ultra House Cleaning</h1>
                </div>
            </div>
            <div className="absolute bottom-20 sm:bottom-40 left-1/2 transform -translate-x-1/2">
                <button 
                    onClick={handleButtonClick}
                    className="bg-white text-black px-4 py-2 text-lg sm:text-xl border-2 border-gray-500 shadow-2xl">
                    Get a quote online
                </button>
            </div>
        </div>
    );
}

export default Hero;
