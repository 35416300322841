function TermsOfService() {
  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 p-8 mt-[60px]">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-10">
        <h1 className="text-4xl font-bold text-center mb-8 text-black">Terms of Service</h1>
        <p className="mb-6 text-lg">
          Welcome to our website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Ultra House Cleaning's relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.
        </p>
        <p className="mb-6 text-lg">
          The term 'Ultra House Cleaning' or 'us' or 'we' refers to the owner of the website. The term 'you' refers to the user or viewer of our website.
        </p>

        <div className="border-l-4 border-gray-400 pl-4 mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-black">Use of the Website</h2>
          <p className="mb-6 text-lg">
            The content of the pages of this website is for your general information and use only. It is subject to change without notice.
          </p>
        </div>

        <div className="border-l-4 border-gray-400 pl-4 mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-black">Privacy Policy</h2>
          <p className="mb-6 text-lg">
            Our privacy policy, which sets out how we will use your information, states that we will use it to let you know about upcoming deals. By using this website, you consent to the processing described therein and warrant that all data provided by you is accurate.
          </p>
        </div>

        <div className="border-l-4 border-gray-400 pl-4 mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-black">User Obligations</h2>
          <p className="mb-6 text-lg">
            You must not misuse this website. You will not: commit or encourage a criminal offense; transmit or distribute a virus, trojan, worm, logic bomb or any other material which is malicious, technologically harmful, in breach of confidence or in any way offensive or obscene; hack into any aspect of the Service; corrupt data; cause annoyance to other users; infringe upon the rights of any other person's proprietary rights; send any unsolicited advertising or promotional material, commonly referred to as "spam"; or attempt to affect the performance or functionality of any computer facilities of or accessed through this Website.
          </p>
        </div>

        <div className="border-l-4 border-gray-400 pl-4 mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-black">Media and Promotional Use</h2>
          <p className="mb-6 text-lg">
            By engaging our services, you acknowledge and agree that Ultra House Cleaning may record, photograph, or otherwise capture images and videos of our staff performing cleaning services. These images and videos may be used for promotional purposes, including but not limited to marketing materials, social media, and advertising campaigns. We assure that any media captured will focus on our staff and the quality of services provided, and will not disclose personal or sensitive information about our clients or their properties without explicit consent.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsOfService;
